/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import React from 'react';


import "./src/css/style.css"


import "@fontsource/montserrat"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/900.css"

// import {AnimatePresence} from "framer-motion"

// export const wrapPageElement = ({element}) => (
    //     <AnimatePresence>{element}</AnimatePresence>
    //   );

require('events').EventEmitter.defaultMaxListeners = 15;